const projectData = [
    {
        title: 'Template',
        sub: 'Template',
        desc: 'Template description.',
        url: '#',
        img: 'https://images.unsplash.com/photo-1542779283-429940ce8336?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
        langs: ['React', 'JavaScript', 'Tailwind', 'CSS'],
    },
    {
        title: 'Template',
        sub: 'Template',
        desc: 'Template description.',
        url: '#',
        img: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80',
        langs: [],
    },
    {
        title: 'Template',
        sub: 'Template',
        desc: 'Template description',
        url: '#',
        img: 'https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
        langs: [],
    },
    {
        title: 'Template',
        sub: 'Template',
        desc: 'Template Description',
        url: '#',
        img: 'https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2020&q=80',
        langs: [],
    },
    {
        title: 'Template',
        sub: 'Template',
        desc: 'Template Description',
        url: '#',
        img: 'https://images.unsplash.com/photo-1496171367470-9ed9a91ea931?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80',
        langs: [],
    },
];

export default projectData;