import React from 'react';
import projectData from './projectdata.js'; // Import your project data here

function safeOpen(event, url) {
    event.preventDefault();
    window.open(url);
}

function Projects() {
    return (
        <div className="grid grid-cols-2 gap-6 mx-auto w-3/4 mt-6 mb-6 max-lg:grid-cols-1 max-lg:gap-6 max-lg:w-5/6">
            {projectData.map((project, index) => (
                // The first card will consume 2 columns while the rest consume 1
                <div key={index} className={`group relative blog-card ${index === 0 ? 'col-span-2' : 'col-span-1'} max-lg:col-span-2 w-full rounded shadow-md bg-white z-0 overflow-hidden`}>
                    {/* Photo */}
                    <div className="relative z-0 h-60">
                        <div className="absolute cover bg-center transition-transform transform">
                            <img src={project.img} alt={project.title} />
                        </div>
                        {/* Meta Details */}
                        <ul className="details absolute top-0 bottom-0 right-full mx-auto transition ease-in-out duration-300 bg-black/40 text-white p-4 w-full font-base">
                            <li className="w-fit underline-offset-4 hover:text-prime">
                                <a href="#">{project.title} - Github Repo</a>
                            </li>
                            <li className="tags">
                                <ul>
                                    <li className="inline-block">{project.langs.join(', ')} </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    {/* Bottom Description */}
                    <div className="relative description p-4 z-1 bg-white">
                        <h2 className="leading-tight m-0 text-3xl">{project.title}</h2>
                        {project.sub && <h3 className="text-base font-light uppercase mt-1.5 text-darkgray">{project.sub}</h3>}
                        <p className="mt-4">{project.desc}</p>
                        <p className="text-right">
                            <a
                                href={project.url}
                                onClick={(e) => safeOpen(e, project.url)}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="relative ha rounded inline-block hover:transition-all ease-in-out duration-300 align-middle after:ml-2 after:content-['_→'] after:opacity-1 hover:font-bold hover:bg-prime hover:text-white hover:pl-2 hover:pr-2"
                            >
                                Visit {project.title}
                            </a>
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Projects;
