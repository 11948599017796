import React from 'react';
function Bio() {
    return (
        // revise bio
        <div className="bio container mx-auto w-3/4 p-8 rounded-lg bg-white/80 mt-20 top-4 left-1/4 opacity-90
        max-lg:w-5/6 max-lg:mt-24 max-lg:rounded-lg">
            <h2 className="text-2xl pb-4">About Me</h2>
            <p className="text-base mx-auto max-lg:text-sm">My journey into the realm of programming began at the age of 12 when I encountered an open-source video game developed in Java. The experience of exploring and modifying the game's code ignited a profound passion for programming within me.
                In January 2023, I made a decisive commitment to furthering my programming skills. I enrolled in a rigorous Full-stack Development bootcamp at UCLA and successfully completed the program in May 2023. This transformative experience marked a significant milestone in my professional journey within the world of software development.</p>
        </div>
    )
}

export default Bio;