import React from 'react';
import github from '../../assets/images/icons/socials/github.svg';
import linkedin from '../../assets/images/icons/socials/linkedin.svg';
import me from '../../assets/images/me.png';
import headerImg from '../../assets/images/header.jpg';

function Header() {
    const my = {
        name: "Brandon Reddington",
        role: "Full-stack Developer",
        bio: "I’m a passionate developer with a strong drive to learn new technology. My primary focus is quality over quantity. I believe that code should be clean and easy to modify.",
        linkedin: "https://www.linkedin.com/in/breddington/",
        github: "https://github.com/b-reddington",
        resume: "https://docs.google.com/document/d/1FXyt3TuPHlNO7uaDMaKfQOae7B8h_UWsF8SZD7H-lFE/edit?usp=sharing",
        location: "Los Angeles, CA",
        status: "Open to work",
    };

    return (
        <>
            {/* Header Image */}
            <img className="w-full h-96 object-cover opacity-70
        max-sm:h-72"
                src={headerImg} alt='an unknown canyon' />
            {/* Avatar Image 
            Update border to match background color */}
            <img className="absolute top-72 left-48 h-56 w-56 object-cover outline outline-offwhite border-4 border-offwhite rounded-full
            max-2xl:left-12
            max-sm:left-2 max-sm:h-32 max-sm:w-32 max-sm:top-60 max-sm:border-2"
                src={me} alt='me' />

            {/* Basic info; name, location, job status, role */}
            <ul className="ml-80 pl-28 mt-3 w-fit
            max-2xl:ml-44
            max-sm:ml-40 max-sm:pl-0">
                <li className="font-semibold text-xl">{my.name}</li>
                <li className="text-base">{my.role}</li>
                <li className="text-sm">{my.location}</li>
                <li className="status open text-sm">{my.status}</li>
            </ul>

            {/* Social Media QL */}
            {/* Update styling -- too plain */}
            <ul className="flex absolute gap-4 top-96 right-60 pt-4 
                max-2xl:grid max-2xl:grid-cols-2 max-2xl:relative max-2xl:right-0 max-2xl:top-0 max-2xl:pt-8 max-lg:w-5/6 max-2xl:w-3/4 max-2xl:mx-auto max-2xl:gap-2">
                <a href={my.linkedin} rel="noopener noreferrer" target="_blank"><li className="btn max-2xl:w-full max-2xl:mt-3 max-2xl:mx-auto max-2xl:col-span-1 transition-all ease-in-out duration-200 hover:bg-prime hover:text-white hover:font-semibold">
                    LinkedIn</li></a>

                <a href={my.github} rel="noopener noreferrer" target="_blank">
                    <li className="btn max-2xl:w-full max-2xl:mt-3 max-2xl:mx-auto max-2xl:col-span-1 transition-all ease-in-out duration-200 hover:bg-prime hover:text-white hover:font-semibold">Github</li></a>

                <a className="max-2xl:col-span-2" href="#contact">
                    <li className="btn max-2xl:w-full max-2xl:mx-auto max-2xl:col-span-2 transition-all ease-in-out duration-200 hover:bg-prime hover:text-white hover:font-semibold">Contact</li>
                </a>

                <a className="max-2xl:col-span-2" href={my.resume} rel="noopener noreferrer" target="_blank">
                    <li className="btn max-2xl:w-full max-2xl:mx-auto max-2xl:col-span-2 transition-all ease-in-out duration-200 hover:bg-prime hover:text-white hover:font-semibold">Resume</li>
                </a>

            </ul>
        </>
    )
}

export default Header;