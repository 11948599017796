function sendEmail() {

}

function Contact() {
    return (
        <div id="contact" className="bg-white/20 w-3/4 h-fit mx-auto pt-24 pb-24 text-center mt-32 mb-32 border-dashed border-2 border-prime max-lg:w-5/6">
            <h2 className="text-4xl pb-1">Let's Connect!</h2>
            <p className="pb-6 w-3/6 mx-auto text-base max-lg:w-3/4 max-lg:text-center max-lg:text-sm">I am currently available for Full-time opportunities as well as contract work. I look forward to hearing from you!</p>
    <a className="mx-auto border-solid pl-4 pr-4 border-2 border-prime p-2 hover:pr-6 hover:pl-6 hover:font-bold hover:bg-prime hover:text-white transition-all duration-200" href="mailto:breddington.dev@gmail.com">Email Me!</a>
        </div>
    )
}

export default Contact;