import Header from './components/Header/Header.jsx';
import Bio from './components/Bio/Bio.jsx';
import Projects from './components/Projects/Projects.jsx';
import Contact from './components/Contact/Contact.jsx';

function App() {
  return (
    <>
      <Header />
      <Bio />
      <Projects />
      <Contact />
    </>
  );
}

export default App;
